<template>
    <router-link :to="{name:'detalleoferta', params:{id:oferta.id}}">
    <div class="aa-oferta-card-home" :style="'background-image: url('+oferta.portada+')'">
        <div class="aa-oferta-card-home-text">
            <span class="aa-text-h2 text-start d-block text-uppercase ps-1 pb-1">
                {{oferta.nombre}}
            </span>
        </div>
    </div>
    </router-link>
</template>

<script>
export default {
    props: {
        datos: {
            type: Object,
            require: true
        },
    },
    setup(props){
        return {
            oferta: props.datos,
        }
    }

}
</script>

<style>

</style>